import React, { createContext, useState, useEffect } from 'react';
import Debug from 'debug';

export const DebugContext = createContext();

const debuggerSetup = debugMode => {
  const debugHash = {};
  // camelCase only
  [
    'main',
    'crudu',
    'sfv',
    'userperms',
    'customsearch',
    'usermfa',
    'accounts',
    'requests',
    'activities',
  ].forEach(
    name => (debugHash[name] = debugMode ? Debug(`ws-app:${name}`) : () => {})
  );
  return debugHash;
};

const Debugger = ({ children, debugEnabled }) => {
  const [debugHash] = useState(() => debuggerSetup(debugEnabled));
  useEffect(() => (debugEnabled ? debugMessage(debugHash) : undefined));
  return (
    <DebugContext.Provider value={debugHash}>{children}</DebugContext.Provider>
  );
};

// message to display mode
const debugMessage = debugHash => {
  const t = 'font-weight: bold; font-size: 20px;',
    gt = t + 'color: #00e26b;',
    n = 'font-size: 12px;',
    nb = n + 'color: #8e84cb;',
    ng = n + 'color: #00e26b;',
    nr = n + 'color: #f94f81;';

  const message = [
    '%c DEBUG MODE: %cENABLED',
    '%c-----------------------------------',
    '%cTo toggle debug logging for a namespace you can set the values in your browsers local storage:',
    '%clocalStorage.debug %c= %c"[namespace]" %c=> %clocalStorage.debug %c= %c"ws-app:main"',
    '',
    '%cTo toggle debug logging for a namespace you can modify the debug object:',
    '%cdebug.namespace.enabled()',
    '',
    '%cYou can specify the target or use a wildcard',
    'DEBUG=%c* %c|| DEBUG=%cws-app:* %c|| DEBUG=%ws-app:main',
    '%cFor more details go to: https://www.npmjs.com/package/debug',
  ].join('\n');

  console.log(
    message,
    t + 'padding-top: 10px;',
    gt,
    t,
    n,
    nr,
    n,
    ng,
    nb,
    nr,
    n,
    ng,
    n,
    nr,
    n,
    nb,
    n,
    nb,
    n,
    nb,
    n + 'padding: 10px 0;'
  );
  console.log(debugHash);
};

export default Debugger;
