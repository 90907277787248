import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { format } from 'date-fns';
import fetch from 'node-fetch';
import { toast } from 'react-toastify';

export const initialState = {};

const slice = createSlice({
  name: 'accountDetails',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state[payload.fundId] = payload.data;
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { set, clear } = slice.actions;

export default slice.reducer;

export const fetchWithFilters = (
  token,
  fundId,
  { dateFilter, typeFilter, dateFilterMap, typeFilterMap }
) => async (dispatch, getState) => {
  const { accountDetails } = getState();
  // only fetch if ID not already in store
  if (!accountDetails[fundId] || 1 == 1) {
    // TODO: forcing fetch due to stale data
    // temp disable
    try {
      const type = typeFilterMap[typeFilter];
      const [start, end] = dateFilterMap[dateFilter];
      const query = `${
        process.env.BASE_PATH
      }/api/accounts/activity?fund=${fundId}&type=${type}&start=${format(
        start,
        'yyyy-MM-dd'
      )}&end=${format(end, 'yyyy-MM-dd')}`;

      const props = { headers: { Authorization: token } };
      const response = await fetch(query, props);

      if (response.ok) {
        const data = await response.json();
        dispatch(set({ fundId, data }));
      } else {
        throw new Error(`${response.statusText}`);
      }
    } catch (e) {
      toast.error('There was an issue with your request');
      Sentry.captureException(e);
    }
  }
};
