import { createSlice } from '@reduxjs/toolkit';
import fetch from 'node-fetch';
import _ from 'lodash';
import { getIdFromRef } from 'services/utils';

export const initialState = {
  name: null,
  type: null,
  accountID: null,
  balance: 0,
  return_sweep: 0,
  account: null,
  shareFileFolderId: null,
};

const slice = createSlice({
  name: 'fund',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { set, clear } = slice.actions;

export default slice.reducer;
// TODO api call should really be /account/[id]/funds
export const getFund = (
  token,
  id,
  name = 'Worth Street Fund'
) => async dispatch => {
  const res = await fetch(`${process.env.BASE_PATH}/api/funds/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  const data = await res.json();
  const fund = data.data
    ? _.find(data.data, o => {
        return o.data.name === name;
      })
    : {};
  dispatch(set({ ...fund.data, id: getIdFromRef(fund) }));
};
