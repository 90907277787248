import { createSlice } from '@reduxjs/toolkit';
import fetch from 'node-fetch';

export const initialState = {
  organization: null,
  wealthboxID: null,
  accountID: null,
  name: null,
  active: null,
  ein: null,
  id: null,
  address: null,
  principal: null,
  kind: null,
  street_line_1: null,
  street_line_2: null,
  city: null,
  state: null,
  zip_code: null,
  country: null,
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { set, clear } = slice.actions;

export default slice.reducer;

export const getAccount = (token, id) => async dispatch => {
  const res = await fetch(`${process.env.BASE_PATH}/api/accounts/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  const data = await res.json();
  dispatch(set({ ...data.data, id }));
};
